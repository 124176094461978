import {
  AccessDeniedComponent,
  AdminUiModule,
  DashboardComponent,
  ErrorComponent,
  LayoutComponent,
  LoginComponent,
  NotFoundComponent,
  NotificationsComponent,
} from '@acal-frontend/admin-ui';
import { AuthGuard } from '@acal-frontend/ui-guards';
import {
  apiInterceptorProvider,
  errorInterceptorProvider,
  jwtInterceptorProvider,
} from '@acal-frontend/ui-interceptors';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,

    // Routing
    RouterModule.forRoot(
      [
        {
          path: '',
          component: LayoutComponent,
          canActivate: [AuthGuard],
          canLoad: [AuthGuard],

          children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

            {
              path: 'dashboard',
              component: DashboardComponent,
              canActivate: [AuthGuard],
            },

            {
              path: 'countries',
              loadChildren: () =>
                import('./country/country.module').then((m) => m.CountryModule),
            },

            {
              path: 'currencies',
              loadChildren: () =>
                import('./currency/currency.module').then(
                  (m) => m.CurrencyModule,
                ),
            },

            {
              path: 'experts',
              loadChildren: () =>
                import('./expert/expert.module').then((m) => m.ExpertModule),
            },

            {
              path: 'expertises',
              loadChildren: () =>
                import('./expertise/expertise.module').then(
                  (m) => m.ExpertiseModule,
                ),
            },

            {
              path: 'global-params',
              loadChildren: () =>
                import('./global-param/global-param.module').then(
                  (m) => m.GlobalParamModule,
                ),
            },

            {
              path: 'hourly-rate-configs',
              loadChildren: () =>
                import('./hourly-rate-config/hourly-rate-config.module').then(
                  (m) => m.HourlyRateConfigModule,
                ),
            },

            {
              path: 'languages',
              loadChildren: () =>
                import('./language/language.module').then(
                  (m) => m.LanguageModule,
                ),
            },

            {
              path: 'notifications',
              component: NotificationsComponent,
              canActivate: [AuthGuard],
            },

            {
              path: 'payments',
              loadChildren: () =>
                import('./payment/payment.module').then((m) => m.PaymentModule),
            },

            {
              path: 'settlements',
              loadChildren: () =>
                import('./settlement/settlement.module').then(
                  (m) => m.SettlementModule,
                ),
            },

            {
              path: 'transfer-fee-configs',
              loadChildren: () =>
                import('./transfer-fee-config/transfer-fee-config.module').then(
                  (m) => m.TransferFeeConfigModule,
                ),
            },
          ],
        },

        { path: 'login', component: LoginComponent },
        { path: 'error', component: ErrorComponent },
        { path: '404', component: NotFoundComponent },
        { path: '403', component: AccessDeniedComponent },
        { path: '**', redirectTo: '404' },
      ],
      { initialNavigation: 'enabledBlocking' },
    ),

    // 3rd-party Libs
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    // ACAL Libs
    AdminUiModule,
  ],

  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        /**/
      },
      deps: [Sentry.TraceService],
      multi: true,
    },

    { provide: 'API_BASE_URL', useValue: environment.apiBaseUrl },
    { provide: 'API_PAGE_SIZE', useValue: environment.apiPageSize },
    { provide: 'APP_SCOPE', useValue: environment.scope },
    { provide: 'APP_VERSION', useValue: environment.appVersion },
    { provide: 'AUTH_API', useValue: environment.authApi },
    { provide: 'IMAGE_BASE_URL', useValue: environment.imageBaseUrl },
    {
      provide: 'JWT_REFRESH_TOKEN_NONCE_NAME',
      useValue: environment.jwt.refreshTokenNonce,
    },
    { provide: 'OZD__SELF_SERVICE_PROFILES', useValue: null },
    { provide: 'PUBLIC_URL', useValue: environment.publicUrl },
    { provide: 'SOCKET_IO_URL', useValue: environment.socketIoUrl },

    jwtInterceptorProvider,
    apiInterceptorProvider,
    errorInterceptorProvider,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
